<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Fluxos</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      Crie e configure seus fluxos
    </h6>
    <hr />
  </div>
  <div class="flex flex-col w-full justify-center items-center pt-10" *ngIf="pager.total == 0">
    <div class="relative">
      <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3966_18110)">
          <circle cx="78.125" cy="78.125" r="28.375" stroke="url(#paint0_linear_3966_18110)" stroke-width="1.5"
            shape-rendering="crispEdges" />
        </g>
        <circle cx="78.125" cy="78.125" r="51.375" stroke="url(#paint1_linear_3966_18110)" stroke-width="1.5" />
        <circle cx="78" cy="78" r="77.25" stroke="url(#paint2_linear_3966_18110)" stroke-width="1.5" />
        <path
          d="M70 79C71.1 79 72 78.1 72 77C72 75.9 71.1 75 70 75C68.9 75 68 75.9 68 77C68 78.1 68.9 79 70 79ZM71.13 80.1C70.76 80.04 70.39 80 70 80C69.01 80 68.07 80.21 67.22 80.58C66.48 80.9 66 81.62 66 82.43V84H70.5V82.39C70.5 81.56 70.73 80.78 71.13 80.1ZM86 79C87.1 79 88 78.1 88 77C88 75.9 87.1 75 86 75C84.9 75 84 75.9 84 77C84 78.1 84.9 79 86 79ZM90 82.43C90 81.62 89.52 80.9 88.78 80.58C87.93 80.21 86.99 80 86 80C85.61 80 85.24 80.04 84.87 80.1C85.27 80.78 85.5 81.56 85.5 82.39V84H90V82.43ZM82.24 79.65C81.07 79.13 79.63 78.75 78 78.75C76.37 78.75 74.93 79.14 73.76 79.65C72.68 80.13 72 81.21 72 82.39V84H84V82.39C84 81.21 83.32 80.13 82.24 79.65ZM74.07 82C74.16 81.77 74.2 81.61 74.98 81.31C75.95 80.93 76.97 80.75 78 80.75C79.03 80.75 80.05 80.93 81.02 81.31C81.79 81.61 81.83 81.77 81.93 82H74.07ZM78 74C78.55 74 79 74.45 79 75C79 75.55 78.55 76 78 76C77.45 76 77 75.55 77 75C77 74.45 77.45 74 78 74ZM78 72C76.34 72 75 73.34 75 75C75 76.66 76.34 78 78 78C79.66 78 81 76.66 81 75C81 73.34 79.66 72 78 72Z"
          fill="#4213F6" />
        <defs>
          <filter id="filter0_d_3966_18110" x="45.1" y="48.1" width="66.05" height="66.05" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="1.95" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0.06 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3966_18110" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3966_18110" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_3966_18110" x1="78.125" y1="49" x2="78.125" y2="96.7949"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
          <linearGradient id="paint1_linear_3966_18110" x1="78.125" y1="26" x2="78.125" y2="111.538"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
          <linearGradient id="paint2_linear_3966_18110" x1="78" y1="0" x2="78" y2="128" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D0D0FF" />
            <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="flex justify-center flex-col items-center -mt-10">
      <h2 class="text-black font-black text text-2xl">Você ainda não tem flows adicionadas</h2>
      <h5 class="text-gray-600 font-light my-3">Adicione seu primeiro</h5>
      <button
        class="mt-3 w-[200px] h-[40px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="openDialog()">+ Adicionar</button>
    </div>

  </div>

  <div class="w-full flex items-center" *ngIf="pager.total > 0">
    <div class="flex w-full flex-col mb-4">
      <div class="flex w-full flex-col">
        <div class="flex gap-2">
          <div class="flex flex-1">

            <label for="simple-search" class="sr-only">Pesquisar</label>
            <div class="relative w-full">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.52625 14.4874C7.34723 14.4874 6.19468 14.1378 5.21436 13.4828C4.23404 12.8278 3.46997 11.8967 3.01878 10.8075C2.56759 9.71819 2.44953 8.51958 2.67955 7.36321C2.90957 6.20684 3.47732 5.14465 4.31101 4.31095C5.14471 3.47726 6.2069 2.9095 7.36327 2.67949C8.51964 2.44947 9.71825 2.56752 10.8075 3.01872C11.8968 3.46991 12.8278 4.23398 13.4829 5.2143C14.1379 6.19462 14.4875 7.34717 14.4875 8.52619C14.4875 9.30904 14.3333 10.0842 14.0337 10.8075C13.7341 11.5307 13.295 12.1879 12.7415 12.7414C12.1879 13.295 11.5308 13.7341 10.8075 14.0337C10.0843 14.3333 9.3091 14.4874 8.52625 14.4874ZM8.52625 3.76036C7.58679 3.76036 6.66843 4.03894 5.8873 4.56088C5.10616 5.08282 4.49734 5.82467 4.13783 6.69261C3.77831 7.56056 3.68425 8.51563 3.86752 9.43704C4.0508 10.3584 4.5032 11.2048 5.1675 11.8691C5.8318 12.5334 6.67817 12.9858 7.59958 13.1691C8.52099 13.3524 9.47605 13.2583 10.344 12.8988C11.2119 12.5393 11.9538 11.9305 12.4757 11.1493C12.9977 10.3682 13.2763 9.44982 13.2763 8.51036C13.2763 7.25058 12.7758 6.0424 11.885 5.1516C10.9942 4.26081 9.78603 3.76036 8.52625 3.76036Z"
                    fill="#757575" />
                  <path
                    d="M15.8333 16.427C15.7553 16.4274 15.678 16.4122 15.606 16.3823C15.5339 16.3523 15.4686 16.3084 15.4137 16.2528L12.1442 12.9833C12.0393 12.8707 11.9822 12.7218 11.9849 12.568C11.9876 12.4142 12.0499 12.2674 12.1587 12.1586C12.2675 12.0499 12.4143 11.9875 12.5681 11.9848C12.7219 11.9821 12.8708 12.0392 12.9833 12.1441L16.2529 15.4137C16.3641 15.525 16.4266 15.6759 16.4266 15.8333C16.4266 15.9906 16.3641 16.1415 16.2529 16.2528C16.1981 16.3084 16.1327 16.3523 16.0607 16.3823C15.9886 16.4122 15.9113 16.4274 15.8333 16.427Z"
                    fill="#757575" />
                </svg>
              </div>
              <input type="search"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5"
                placeholder="Pesquisar..." />
            </div>
          </div>
          <!-- <div class="flex">
            <button type="submit"
              class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900  rounded-lg border global-filter">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                  fill="#667085" />
              </svg>
              <span>
                Filtros
              </span>
            </button>
          </div> -->
          <button
            class="w-[200px] h-[40px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
            (click)="openDialog()">+ Adicionar</button>
        </div>

      </div>
    </div>
  </div>

  <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]" *ngIf="pager.total > 0">
    <div class="inline-block min-w-full py-2 align-middle">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2">
        <table class="min-w-full divide-gray-100 divide-y-[3px]">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Data de criação</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Jornada</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Status</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4">
                <span class="sr-only"></span>
              </th>
            </tr>
          </thead>
          <tbody class="min-w-full divide-gray-100 divide-y-[3px] bg-white">
            <tr class="rounded-none" *ngFor="let item of pager.list">
              <td class="whitespace-nowrap py-4 pl-4 pr-3">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ item.createdAt | date:'shortDate'}}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ item.name}}
                    </div>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                <div class="flex gap-2">
                  <div class="flex flex-col">
                    <div class="font-weight-600">
                      {{ item.description}}
                    </div>
                  </div>
                </div>
              </td>
              <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <div class="inline-block text-left" x-data="{ menu: false }">
                  <button x-on:click="menu = ! menu" type="button"
                    class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button"
                    aria-expanded="true" aria-haspopup="true">
                    <span class="sr-only"></span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                  </button>
                  <div x-show="menu" x-on:click.away="menu = false"
                    class="origin-top-right absolute right-20 w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="" role="none">
                      <a href="javascript:void(0)"
                        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                        role="menuitem" tabindex="-1" id="menu-item-0" [routerLink]="['/flow/edit/', item._id]">
                        Editar
                      </a>
                    </div>
                    <div class="" role="none">
                      <a href="javascript:void(0)"
                        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                        role="menuitem" tabindex="-1" id="menu-item-0" (click)="delete(item._id)">
                        Remover
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>